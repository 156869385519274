<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'categories' }"
          >Exercise Category</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4">
      <div class="align-center d-flex justify-space-between">
        <div class="display-1 font-weight-bold my-6 text-capitalize">
          Update Exercise Category
        </div>
        <template v-if="!loading"> </template>
      </div>

      <div class="col-xl-4 col-lg-6 col-md-12 mt-6 pa-0">
        <v-form v-if="form" @submit.prevent="submit" ref="form">
          <v-divider class="pb-5"></v-divider>

          <label class="text-field-label">Exercise Category Name</label>
          <v-text-field
            flat
            solo
            required
            counter
            class="mt-2"
            maxlength="255"
            v-model="form.category_name"
            :error-messages="form.$getError('category_name')"
          ></v-text-field>
          <label class="text-field-label">Enable</label>
          <v-switch
            v-model="form.is_enabled"
            :error-messages="form.$getError('is_enabled')"
            inset
          ></v-switch>

          <v-col cols="12">
            <div>
              <input v-model="form.photo" hidden />
              <v-img
                size="100%"
                class="mb-4"
                v-if="photoCover"
                :src="photoCover"
                :lazy-src="photoCover"
              ></v-img>
              <v-btn @click="showCategoryUpload = true" color="secondary">
                {{ photoCover ? 'Change' : 'Add' }} Photo
              </v-btn>
            </div>
          </v-col>

          <v-alert v-if="form.$getError('photo')" type="error" class="mt-5">
            {{ form.$getError('photo')[0] }}
          </v-alert>
          <div class="mt-4">
            <v-btn
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="70%"
              @click="submit"
              :loading="form.$busy"
              ><v-icon left dark> {{ icons.update }} </v-icon>Update</v-btn
            >
          </div>
          <div class="mt-4">
            <v-btn
              @click="showDeleteModal = true"
              height="40px"
              width="70%"
              color="error"
              ><v-icon left dark> {{ icons.remove }} </v-icon>Delete
              Category</v-btn
            >
          </div>
        </v-form>
      </div>
    </div>
    <image-cropper v-model="showCategoryUpload" @change="imageChanged" />

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>

    <ConfirmModal
      v-model="showDeleteModal"
      title="Delete Category"
      message="Are you sure you want to delete category?"
      @cancel="showDeleteModal = false"
      @confirm="removeCategory"
    ></ConfirmModal>
  </div>
</template>

<script>
import ImageCropper from '@/components/modals/ImageCropper'
import Form from '@/utils/form'
import { mdiChevronLeft, mdiTrashCan, mdiUpdate } from '@mdi/js'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mapActions, mapState, mapMutations } from 'vuex'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { each } from 'lodash'

export default {
  name: 'CategoryDetails',

  components: {
    AppBarNavIcon,
    ConfirmModal,
    ImageCropper,
  },

  data() {
    return {
      form: null,
      photo: [],
      photoCover: null,
      showCategoryUpload: false,
      showUpload: false,
      showDeleteModal: false,
      icons: {
        arrowLeft: mdiChevronLeft,
        remove: mdiTrashCan,
        update: mdiUpdate,
      },
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
    }
  },

  computed: {
    ...mapState({
      categoryDetails: (state) => state.category.categoryDetails,
    }),
  },

  methods: {
    ...mapActions({
      updateCategory: 'category/updateCategory',
      getCategoryDetails: 'category/getCategoryDetails',
      deleteCategory: 'category/deleteCategory',
    }),

    ...mapMutations({
      clearCategoryDetails: 'category/clearCategoryDetails',
    }),

    async getCategory() {
      this.loading = true
      await this.getCategoryDetails(this.$route.params.id)
      this.form = new Form(this.categoryDetails)
      if (this.categoryDetails.photo) {
        this.photoCover = this.categoryDetails.photo.url
      }
      this.loading = false
    },

    async submit() {
      this.form.$busy = true
      this.form.$clearErrors()
      // since photo is nullable, check if photo object is null
      if (this.form.photo !== null) {
        if (this.form.photo.collection_name) {
          this.form.photo = null
        }
      }

      const formData = new FormData()

      each(this.form.$data(), (value, key) => {
        formData.append(key, value)
      })
      formData.append('_method', 'PUT')

      const data = {
        data: formData,
        id: this.$route.params.id,
      }

      this.updateCategory(data)
        .then(() => {
          this.showSnackbar('Category successfully updated!', 'success')

          setTimeout(() => {
            this.$router.push({
              name: 'categories',
            })
          }, 1000)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    imageChanged(image) {
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.photoCover = reader.result
        this.form.photo = image
      }
    },

    removeCategory() {
      this.loading = true

      this.deleteCategory(this.$route.params.id)
        .then(() => {
          this.$router.push({ name: 'categories' })
        })
        .catch((error) =>
          this.showSnackbar(error.response.data.message, 'error')
        )
        .finally(() => (this.loading = false))
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
  },

  created() {
    this.getCategory()
  },

  destroyed() {
    this.clearCategoryDetails()
  },

  watch: {
    $route() {
      this.getCategory()
    },
  },
}
</script>
